import React, {useRef, useEffect} from 'react'
import {Root, createRoot} from 'react-dom/client'
import Calendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar'

type FullCalendarProps = React.ComponentProps<typeof Calendar>

let root: Root | null = null

const CalendarWrapper = (props: FullCalendarProps) => {
  const calendarRef = useRef(null)

  useEffect(() => {
    window.refetchCalendarEvents = () => {
      if (calendarRef.current) {
        // @ts-expect-error prop getApi does not exist
        const calendarApi = calendarRef.current.getApi()
        calendarApi.refetchEvents()
      }
    }
  }, [])

  return (
    <Calendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin, googleCalendarPlugin, interactionPlugin]}
      {...props}
    />
  )
}

window.renderCalendar = (calendarElementId: string, props: FullCalendarProps) => {
  if (root) root.unmount()

  const rootElement = document.getElementById(calendarElementId)!
  root = createRoot(rootElement)

  root.render(<CalendarWrapper {...props} />)
}
